import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        
      },{
        path: '/serveIndex',
        name: 'serveIndex',
        component: () => import('@/views/pc/serveIndex.vue'),
      },{
        path: '/serveList',
        name: 'serveList',
        component: () => import('@/views/pc/serveList.vue'),
      },{
        path: '/honorIndex',
        name: 'honorIndex',
        component: () => import('@/views/pc/honorIndex.vue'),
      },{
        path: '/caseIndex',
        name: 'caseIndex',
        component: () => import('@/views/pc/caseIndex.vue'),
      },{
        path: '/caseList',
        name: 'caseList',
        component: () => import('@/views/pc/caseList.vue'),
      },{
        path: '/projectList',
        name: 'projectList',
        component: () => import('@/views/pc/projectList.vue'), //重点项目列表
      },{
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/views/pc/newsList.vue'), //重点项目列表
      },{
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/pc/newsDetail.vue'), //重点项目列表
      }
    
     
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      // {
      //   path: '/',
      //   name: 'Index',
      //   component: () => import('@/views/m/index.vue'),
      //   meta:{
      //   }
        
      // },
      // {
      //   path: '/search',
      //   name: 'search',
      //   component: () => import('@/views/m/search.vue'),
      //   meta:{
      //     title:'搜索'
      //   }
      // }
    
     
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
})

export default router
