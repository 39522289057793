<template>
  <div class="home">
    <header-menu v-if="headerState" :logo="logoUrl" />
    <router-view />
    <foot-menu   v-if="footerState" :deepLogoUrl='deepLogoUrl' />
  </div>
</template>
<script>
import headerMenu from "@/components/pc/header.vue";
import footMenu from "@/components/pc/footer.vue";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
      logoUrl: '',
      deepLogoUrl:'',
      topNum:'',
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow,topState } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        this.topNum =  topState ? true : false;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站配置信息
    this.urlState = window.location.host.includes("form_data")
      ? "false"
      : "true";
  },
  methods: {
    getWebsiteInfo() {
      
    },
  },
};
</script>

<style lang="scss">
*{
  // font-family: PingFangSC-Regular, sans-serif;
  font-family: PingFangTC-Regular, sans-serif;
}
.container_box{
  padding-bottom: 150px;
}
 html,
      body {
        position: relative;
        // height: 100%;
      }
ul,li{
  list-style: none;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.abs_50{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.radius_5{
  border-radius: 5px;
}
</style>
