<template>
  <div class="foot_main">
    <div class="footer_top"></div>
    <div class="footer_box">
      <div class="footer_cont w_1200 m_auto">
        <div class="left_about_us">
          <p class="logo"><img src="@/assets/pc/footer/logo.png" alt=""></p>
          <div class="phone_cont">
            <p class="tips">联系我们</p>
            <div class="phone_num flex">
              <img src="@/assets/pc/footer/phone.png" alt="">
              <span>400-0123-823</span>
            </div>
            <p class="adr_text">
              北京市东城区首东国际大厦B座4号楼17层
            </p>
            <div class="code_cont">
              <p class="img">
                <img src="@/assets/pc/footer/code_img.png" alt="">
              </p>
              <p class="text">扫码添加联系我们</p>
            </div>
          </div>
        </div>
        <div class="ul_cont">
          <ul>
            <p>推荐产品</p>
             <!-- <li><a href="http://open.kskstudy.com/" target="_blank" rel="noopener noreferrer">智慧工会平台解决方案</a></li> -->
             <li @click="$router.push('/serveList?num=9')"><p>智慧工会平台解决方案</p></li>
             <li @click="$router.push('/serveList?num=11')"><p>劳动技能大赛解决方案</p></li>
             <li @click="$router.push('/serveList?num=10')"><p>工会职工活动解决方案</p></li>
             <li @click="$router.push('/serveList?num=13')"><p>创新管理系统解决方案</p></li>
             <li @click="$router.push('/serveList?num=12')"><p>劳模管理系统解决方案</p></li>
             <li @click="$router.push('/serveList?num=15')"><p>工会干部专题培训解决方案</p></li>
          </ul>
          <ul>
            <p>关于中工</p>
             <li><a href="https://www.kjcxchina.com/aboutUs" target="_blank" rel="noopener noreferrer">公司简介</a></li>
             <li ><a href="https://www.kjcxchina.com/action" target="_blank" rel="noopener noreferrer">新闻中心</a></li>
             <!-- <li><a href="http://open.kskstudy.com/Join" target="_blank" rel="noopener noreferrer">人才招聘</a></li> -->
             <li><a href="https://www.kjcxchina.com" target="_blank" rel="noopener noreferrer">人才招聘</a></li>
             <li><a href="https://www.kjcxchina.com" target="_blank" rel="noopener noreferrer">发展历程</a></li>
             <li><a href="https://www.kjcxchina.com" target="_blank" rel="noopener noreferrer">社会责任</a></li>
          </ul>
          <ul>
            <p>服务支持</p>
             <li><a href="javascript:;"  rel="noopener noreferrer">政企服务</a></li>
             <li><a href="javascript:;"  rel="noopener noreferrer">合作伙伴</a></li>
             <li @click="aboutUsBtn()"><a href="javascript:;"  rel="noopener noreferrer">联系我们</a></li>
             <!-- <li @click="$router.push('/aboutUs')"><a href="javascript:;"  rel="noopener noreferrer">联系我们</a></li> -->

          </ul>
          <ul>
            <p>友情链接</p>
            <li><a href="http://www.mohrss.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国人力资源和社会保障部</a> </li>
            <li><a href="https://www.miit.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国工业和信息化部</a></li>
            <li><a href="https://www.acftu.org/" target="_blank" rel="noopener noreferrer">中华全国总工会</a></li>
            <!-- <li><a href="http://www.hbzgh.org.cn/" target="_blank" rel="noopener noreferrer">湖北省总工会</a></li>
            <li><a href="https://www.ahghw.org/" target="_blank" rel="noopener noreferrer">安徽省总工会</a></li>
            <li><a href="https://www.scgh.org/" target="_blank" rel="noopener noreferrer">四川省总工会</a></li>
            <li><a href="http://www.nmgzgh.org.cn/" target="_blank" rel="noopener noreferrer">内蒙古自治区总工会</a></li>
            <li><a href="http://www.cfwu.org/" target="_blank" rel="noopener noreferrer">中国金融工会</a></li>
            <li><a href="https://www.3m.com.cn/" target="_blank" rel="noopener noreferrer">3m中国有限公司</a></li>
            <li><a href="http://www.cmpbook.com/" target="_blank" rel="noopener noreferrer">机械工业出版社</a></li> -->
          </ul>
        </div>
        
      </div>
      <div class="deep_cont w_1200 m_auto flex">
        <div class="copy_cont">
          <div class="text_info">
            <p>Copyright © 2015-2023 kjcxchina.com All Rights Reserved</p>
            <p>版权所有：中工科创文化传媒（北京）有限公司    京ICP备16012224号-1</p>
          </div>
        </div>
        <div class="right_cont flex">
          <p class="text">关注我们</p>
          <p class="img"><img src="@/assets/pc/footer/wx_icon.png" alt=""></p>
          <div class="code_img">
            <img src="@/assets/about_us.png" alt="">
          </div>
        </div>

      </div>
    </div>
    <div class="mask_box" v-if="maskState">
      <div class="aboutUs_box">
        <p class="close cursor" @click="maskState=false">×</p>
        <p class="img">
          <img src="@/assets/about_us.png" alt="">
        </p>
        <p class="text">扫码添加联系我们</p>
      </div>
    </div>

     <!-- 右侧固定栏 -->
    <div class="right_fixed_box">
      <div class="cont_data">
        <ul>
          <li class="active item1">
            <div class="show">
              <p class="img img1"><img src="@/assets/icon1_1.png" alt="" /></p>
              <p class="img img2"><img src="@/assets/icon1.png" alt="" /></p>
              <p class="text">咨询服务</p>
            </div>
            <div class="left_cont">
              <div class="cont_info">
                <p class="flex img">
                  <img src="@/assets/phone.png" alt="" />咨询电话
                </p>
                <p class="phone_num">400-0123-823</p>
              </div>
              <div class="cont_info item2 flex">
                <p class="left_img">
                  <img src="@/assets/codeImg.png" alt="" />
                </p>
                <p class="text">扫码 <br />咨询详情</p>
              </div>
            </div>
          </li>
          <li @click="formMaskState = true" class="cursor">
            <div class="show">
              <p class="img"><img src="@/assets/icon2.png" alt="" /></p>
              <p class="text">合作预约</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 弹框 -->
    <div class="mask_cont_box" v-if="formMaskState">
      <div class="cont_data">
        <p class="close" @click="closeMask()">
          <img src="@/assets/close.png" alt="" />
        </p>
        <p class="title">合作预约</p>
        <ul class="cont_info">
          <li>
            <div class="item1">
              <p class="text">姓名</p>
              <p class="input">
                <el-input
                  v-model="formData.name"
                  placeholder="请填写您的姓名"
                ></el-input>
              </p>
            </div>
            <div>
              <p class="text">手机号</p>
              <p class="input">
                <el-input
                  v-model="formData.phone"
                  placeholder="请填写您的手机号"
                ></el-input>
              </p>
            </div>
          </li>
          <li>
            <div>
              <p class="text">企业</p>
              <p class="input">
                <el-input
                  v-model="formData.company"
                  placeholder="请填写您的企业名称"
                ></el-input>
              </p>
            </div>
          </li>
          <li>
            <div>
              <p class="text">需求</p>
              <p class="input">
                <el-select
                  v-model="formData.demand"
                  placeholder="请选择您的需求"
                >
                  <el-option label="平台搭建" value="1"></el-option>
                  <el-option label="技能大赛" value="2"></el-option>
                  <el-option label="职工活动" value="3"></el-option>
                  <el-option label="创新管理" value="4"></el-option>
                  <el-option label="其他" value="0"></el-option>
                </el-select>
                <!-- <el-input v-model="formData.demand" placeholder="请选择您的需求"></el-input>-->
              </p>
            </div>
          </li>
        </ul>
        <div class="btn cursor" @click="submitBtn()">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
      maskState:false,
      formMaskState: false,
      formData: {
        name: "",
        phone: "",
        company: "",
        demand: "",
      },
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
    maskState1:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    maskState1(newValue,oldValue){
      this.maskState = newValue
    }
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  methods: {
    aboutUsBtn(){
      this.maskState=true
      this.$emit('state','true')
    },
     // 提交
    submitBtn() {
      let formInfo = new FormData();
      formInfo.append("name", this.formData.name);
      formInfo.append("phone", this.formData.phone);
      formInfo.append("company", this.formData.company);
      formInfo.append("demand", this.formData.demand);
      this.$axios.post(`/union/index/cooperate`, formInfo).then((res) => {
        if (res.code == 1) {
          this.$message.success("报名成功");
          this.closeMask();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关闭
    closeMask() {
      this.formMaskState = false;
      for (let i in this.formData) {
        this.formData[i] = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/utils/common.scss";
// .footer_top{
//   width: 100%;
//   height: 192px;
//   background: #F7FBFC;
// }
.footer_box{
  background: #000;
  // background: url('@/assets/pc/index/footer_bgm.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 95px 0 26px;
  position: relative;
  .footer_cont{
    width: 1200px;
    // margin: 0 auot;
    
    display: flex;
    .left_about_us{
      border-right: 1px solid #333;
      padding-right: 66px;
      padding-bottom: 35px;
      .phone_cont{
        margin-top: 60px;
        p.tips{
          font-size: 16px;
          color: #FFFFFF;
        }
        div.phone_num{
          margin-top: 19px;
          span{
            font-size: 20px;
            color: #03E5C4;
            margin-left: 7px;
          }
        }
        .adr_text{
          margin-top: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
        .code_cont{
          margin-top: 40px;
          p.text{
            margin-top: 10px;
            font-size: 12px;
            color: #FFFFFF;
          }
          img{
            width: 98px;
          }
        }

      }
    }
    .ul_cont{
      flex: 1;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-left: 65px;
      ul{
        // margin-right: 120px;
        p{
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 39px;
        }
        li{
          
          margin-bottom: 17px;
          font-size: 0;
          a,p{
            font-size: 16px;
          color: #C1C1C1;
          margin-bottom: 0;
          cursor: pointer;
          }
        }
      }
    }
  }
  .deep_cont{
    border-top: 1px solid #333;
    justify-content: space-between;
    padding-top: 16px;
    .text_info{
      p{
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #C1C1C1;
        margin-bottom: 13px;
      }
    }
    .right_cont{
      position: relative;
      p.text{
        font-size: 18px;
        color: #C1C1C1;
        margin-right: 43px;
      }
      .code_img{
        position: absolute;
        bottom: 40px;
        right: 0%;
        display: none;
        img{
          width: 62px;
        }
      }
    }
    .right_cont:hover{
      .code_img{
        display: block;
      }
    }
  }
}
.mask_box{
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100vh;
  z-index: 999;
  .aboutUs_box{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-sizing: border-box;
    p.close{
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      padding:0 10px;
      
    }
    p.img{
      width: 200px;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
    p.text{
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
    }
  }
}

// 右侧固定栏
.right_fixed_box {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  .cont_data {
    ul {
      li {
        position: relative;
        border: 1px solid #ededed;
        .show {
          width: 90px;
          height: 90px;
          background: #ffffff;
          overflow: hidden;
          p.img {
            display: flex;
            justify-content: center;
            margin-top: 13px;
          }
          p.img2 {
            display: none;
          }
          p.text {
            text-align: center;
            margin-left: 5px;
            font-size: 14px;
            color: #333333;
          }
        }
        .left_cont {
          display: none;
          position: absolute;
          right: 90px;
          top: 0;
          width: 176px;
          height: 180px;
          box-sizing: border-box;
          .cont_info {
            height: 90px;
            text-align: center;
            background: url("@/assets/bgm.png") no-repeat;
            background-size: 100% 100%;
            p.img {
              font-size: 16px;
              color: #3e92f7;
              padding-top: 18px;
              margin-left: 30px;
              img {
                width: 22px;
                margin-right: 5px;
              }
            }
            p.phone_num {
              margin-top: 8px;
              font-size: 16px;
              font-weight: 800;
              color: #333333;
            }
          }
          .item2 {
            justify-content: center;
            border-top: none;
            border: 1px solid #ededed;
            background: #fff;
            margin-right: 9px;
            p.left_img {
              width: 70px;
              margin-right: 10px;
              img {
                width: 100%;
              }
            }
            p.text {
              text-align: left;
            }
          }
        }
      }
      // li:first-child{
      //   border-bottom: none;
      // }
      li.item1:hover {
        border: 1px solid #3e92f7;
        .show {
          background: #3e92f7;
          p.text {
            color: #fff;
          }
          p.img1 {
            display: none;
          }
          p.img2 {
            display: flex;
          }
        }
      }

      .item1:hover .left_cont {
        display: block;
      }
    }
  }
}

.mask_cont_box .cont_data {
  width: 370px;
  height: 382px;
  
  p.close {
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px 0 0;
    cursor: pointer;
  }
  p.title {
    text-align: center;
    margin: -10px 0 14px;
    font-size: 20px;
    color: #333333;
  }
  .cont_info {
    margin: 0 20px;
    li {
      display: flex;
      margin-bottom: 14px;
      div {
        flex: 1;
        p.text {
          font-size: 14px;
          color: #333333;
          margin-bottom: 8px;
        }
        .el-select {
          width: 100%;
        }
      }
      .item1 {
        margin-right: 10px;
      }
    }
  }
  div.btn {
    width: 181px;
    height: 40px;
    background: #3e92f7;
    border-radius: 3px 3px 3px 3px;
    margin: 30px auto 0;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
  }
}
</style>
