<template>
  <div class="header_box">
    <div class="head_cont">
      <div class="menu_box  flex">
        <p class="logo">
          <img src="@/assets/pc/logo.png" alt="" />
          <span class="line"></span>
          <span class="text">领创工会工作新格局</span>
        </p>
        <ul class="flex titleMenu">
          <li class="post">
              <router-link :class="$route.path=='/'?'activeMenu':''" to="/"
                >主页</router-link
              >

          </li>
          <li class="post">
            <router-link active-class="activeMenu" to="/serveIndex"
              >解决方案&服务</router-link
            >
          </li>
          <li class="post">
            <router-link active-class="activeMenu" to="/caseIndex"
              >客户&案例</router-link
            >
          </li>
          <li class="post">
            <router-link active-class="activeMenu" to="/honorIndex"
              >荣誉</router-link
            >
          </li>
        </ul>
        <!-- <p class="btn_text">在线咨询</p> -->
        <!-- <p class="phone_text">400-0123-823</p> -->
     
      </div>
    </div>
    <!-- 登录框 -->
    <!-- v-if="loginModal.show" -->
    <!-- <Login v-if="loginModal.show" :showIndex="showIndex" /> -->
  </div>
</template>

<script>
// import Login from "@/components/pc/login.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "headers",
  data() {
    return {
      showIndex: 1,
      userCont:{},
      user_avater:'',//头像
    };
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  components: {
    // Login,
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  mounted() {
    this.userCont = sessionStorage.getItem('userData')?JSON.parse(sessionStorage.getItem('userData')):''
    this.user_avater = sessionStorage.getItem('user_avater')?JSON.parse(sessionStorage.getItem('user_avater')):''
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
      "ShowTipModal",
    ]), //登录弹窗

    // 登录
    goLogin() {
      this.showIndex = 1;
      this.ShowLoginModal();
    },
    // 注册
    goRegister() {
      this.showIndex = 2;
      this.ShowLoginModal();
    },
    // 退出登录
    signOut(){
      sessionStorage.clear()
      location.reload();
    }
  },
};
</script>

<style scoped lang="scss">
// *{
//   margin: 0;
//   padding: 0;
// }
.header_box {
  .head_cont {
    height: 90px;
    background: #ffffff;
     position: relative;
     box-sizing: border-box;
    //  padding: 0 8%;
     
    .menu_box {
      height: 100%;
      min-width: 1200px;
      max-width: 1470px;
      margin: 0 auto;
      p.logo {
        height: 52x;
        margin-right: 55px;
        display: flex;
        align-items: center;
        img {
          width: auto;
          height: 46px;
        }
        span{
          display: block;
        }
        span.line{
          width: 1px;
          height: 33px;
          background: #C3C3C3;
          margin: 0 17px;
        }
        span.text{
          font-size: 16px;
          color: #707070;
        }
      }
      ul {
        flex: 1;
        height: 100%;
        justify-content: center;
        
        li {
          font-size: 14px;
          margin-right: 50px;
          // position: relative;
          height: 100%;
          a {
            line-height: 90px;
            font-size: 18px;
          }
        }
        li.post{
          position: relative;
        }
        li:last-child {
          margin-right: 0;
        }
        // .activeMenu::after {
        //   content: "";
        //   width: 30px;
        //   height: 2px;
        //   display: block;
        //   position: absolute;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   bottom: 0px;
        //   background-color: #0080ff;
        //   border-radius: 30px;
        // }
        .activeMenu {
          color: #0080ff;
          font-weight: bold;
        }
      }
      .btn_text{
        width: 116px;
        height: 28px;
        background: #3E92F7;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        line-height: 28px;
        color: #fff;
        cursor: pointer;
      }
      .phone_text{
        font-size: 28px;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #3E92F7;
      }
    }

   
  }
}

</style>
